import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, useLocation } from 'react-router-dom';
import { useAuth } from '@primaveralabs/auth';
import getNavigatorSection from '../../../util/getNavigatorSection';
import Navigator from '../Navigator';
import RadioContainer from '../Radio/RadioContainer';
import { usePost, useRoutes } from '../../../context/routesContext';
import Chat from '../MusicMeets/Chat/Chat';
import { CURRENT_EDITION } from '../MusicMeets/Header/HeaderMusicMeets';
import { LoginButton } from '../MusicMeets/Agenda';
import PaywallNeedsRegistration from '../Paywall/NeedsRegistration';

const PRO_PATHS = [
  'pro-online',
  'pro-directory',
  'pro-mentoring-sessions',
  'pro-mentoring-sessions-detail',
  'pro-reunions',
  'pro-create-reunion',
  'pro-reunion-invitation-sent',
  'user-profile-pro-online',
  'pro-member-detail',
];

const Body = ({ children, match }) => {
  // NOTE: Hide Chat for 2024 edition and instead send email
  // const { token, user } = useAuth();
  // const { pathname } = useLocation();
  // const { language } = useRoutes();

  const { components } = children;
  const { currentRolesAllowed, currentPostDescription: { removeBodyChildrenMargin } } = usePost();
  const { user } = useAuth();
  const { paywall = {}, requiresCompleteRegistration } = currentRolesAllowed ?? {};
  const { image, translations, terms } = paywall;
  const [{ type = '', components: [{ type: type2 = '' } = {}] = [] } = {}] = components || [];

  const userNeedsToBeLoggedIn = requiresCompleteRegistration && !user

  const showNavigator = (
    (type === 'Carousel' || type2 === 'Carousel')
    && getNavigatorSection(match.params)
  );

  // NOTE: Hide Chat for 2024 edition and instead send email
  // const showChatMusicMeets = pathname !== `/${language}`
  // && PRO_PATHS.some((p) => p.includes(pathname.replace(`/${language}/`, '').replace('/', '')))
  // && token && user?.roles.includes(CURRENT_EDITION);

  return (
    <>
      <div id="body" className={`${removeBodyChildrenMargin ? 'removeBodyChildrenMargin' : ''} w-full flex flex-col items-center relative min-h-screen-70`}>
        {userNeedsToBeLoggedIn ? <PaywallNeedsRegistration image={image} translations={translations} terms={terms} /> : children}
        {showNavigator && <Navigator />}
      </div>
      {/* {showChatMusicMeets && <Chat match={match} />} */}
      <RadioContainer />
    </>
  );
};

Body.propTypes = {
  children: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
};

export default withRouter(Body);
