/* eslint-disable react/jsx-filename-extension */
import React, { useContext, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { PropTypes } from 'prop-types';
import getLocalization from '../util/localization';

let updateUserRoute;

const userRoute = {
  language: 'en',
  enteredFrom: 'root',
  updateUserRoute: (language, enteredFrom) => updateUserRoute(language, enteredFrom),
};


updateUserRoute = (language, enteredFrom) => {
  userRoute.language = language;
  userRoute.enteredFrom = enteredFrom;
};

const RoutesContext = React.createContext(userRoute);

export const useRoutes = (matchFromComponent) => {
  // NOTE: sending matchFromComponent is @deprecated
  const match = matchFromComponent || useRouteMatch();
  const { language, enteredFrom } = useContext(RoutesContext);
  return getLocalization(language, enteredFrom, match);
};

export const usePost = () => useContext(RoutesContext);

export const RoutesProvider = ({ children }) => {
  const [post, setPost] = useState({});

  const { postDescription, slugName, rolesAllowed, postCategory, requiresCompleteRegistration, paywall } = post;

  return (
    <RoutesContext.Provider value={{
      ...userRoute,
      currentPostDescription: postDescription || {},
      currentSlugName: slugName,
      currentRolesAllowed: {
        paywall,
        slugName,
        rolesAllowed,
        url: postDescription?.url,
        requiresCompleteRegistration,
      },
      currentPostCategory: postCategory,
      setPost,
    }}
    >
      {children}
    </RoutesContext.Provider>
  );
};

RoutesProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default RoutesContext;
