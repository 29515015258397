import React, { useContext, useEffect, useState } from 'react';
import { LoginButton } from '../MusicMeets/Agenda';
import { LinkWrapper } from '../../../util/link';
import { useRoutes } from '../../../context/routesContext';
import translateObj from '../../../util/translateObj';
import Spacer from '../Spacer';

const DEFAULT_TRANSLATIONS = {
  description: {
    en: 'You need to be logged in or create an account to view this content.',
    es: 'Necesitas estar logeado o crear una cuenta para ver este contenido.',
    ca: 'Necessites estar registrat o crear un compte per veure aquest contingut.',
    pt: 'Você precisa estar logado ou criar uma conta para ver este conteúdo',
  }
}

const PaywallNeedsRegistration = ({ image, translations = DEFAULT_TRANSLATIONS, terms }) => {
  const { language, path } = useRoutes();

  return (
    <div className='sm:w-full w-2/3 mt-16 flex flex-col items-center'>
      {
        image ? (
        <div>
          <img src={image} />
        </div>
        ) : null
      }
      <div>
        <LoginButton translation={{ textLogin: translations.description }}/>
      </div>
      {
        terms?.text.en 
          ? <LinkWrapper url={terms.link} path={path} className="text-black underline">
              {translateObj(terms.text, language)}
            </LinkWrapper>
          : null
      }
      <Spacer className="mt-20" />
    </div>
  );
  };
  
  
  export default PaywallNeedsRegistration;