const artistsList = [
  'alison-goldfrapp',
  'new-order',
  'marcellus-pittman',
  'purple-disco-machine',
  'vitalic',
  'crack-cloud',
  'calibro-35',
  'enumclaw',
  'far-caspian',
  'ghost',
  'israel-fernandez-diego-del-morao',
  'just-mustard',
  'las-robertas',
  'melodys-echo-chamber',
  'the-goa-express',
  'zopa',
  'bad-gyal',
  'black-midi',
  'leclair',
  'nyege-nyege-presents',
  'the-mars-volta',
  'goat-girl',
  'folamour-a-v',
  'georgia',
  'hickeys',
  'joy-anonymous',
  'pet-shop-boys',
  'la-paloma',
  'jake-bugg',
  'confidence-man',
  'yung-lean',
  'wooden-wisdom-and-dj-fitz',
  'wednesday',
  'villano-antillano',
  'two-shell',
  'tokischa',
  'the-war-on-drugs',
  'the-voidz',
  'surf-curse',
  'st-vincent',
  'shellac',
  'rosalia',
  'pional',
  'overmono',
  'nuria-graham',
  'my-morning-jacket',
  'maneskin',
  'maggie-rogers',
  'laurie-anderson',
  'kelela',
  'jpegmafia',
  'josey-rebelle',
  'john-cale',
  'jockstrap',
  'jayda-g',
  'james-ellis-ford',
  'halsey',
  'grupo-de-expertos-solynieve',
  'gilla-band',
  'ezra-furman',
  'eddie-palmieri',
  'dj-coco',
  'death-grips',
  'charlotte-de-witte',
  'caroline-polachek',
  'calvin-harris',
  'bar-italia',
  'avalon-emerson',
  'arlo-parks',
  'unwound',
  'the-soft-pink-truth-live',
  'the-moldy-peaches',
  'the-delgados',
  'the-beths',
  'tems',
  'swans',
  'sparks',
  'skrillex',
  'pusha-t',
  'nation-of-language',
  'lebanon-hanover',
  'kyary-pamyu-pamyu',
  'kendrick-lamar',
  'karate',
  'julia-jacklin',
  'julia-holter',
  'john-talabot',
  'japanese-breakfast',
  'gaz-coombes',
  'fred-again',
  'daphni',
  'christine-and-the-queens',
  'channel-tres',
  'cavetown',
  'built-to-spill',
  'blur',
  'bleachers',
  'bill-kouligas',
  'beth-orton',
  'beak',
  'bala',
  'bad-religion',
  'baby-keem',
  'alvvays',
  'yves-tumor',
  'yard-act',
  'turnstile',
  'tsvi',
  'the-comet-is-coming',
  'terno-rei',
  'sudan-archives',
  'soul-glo',
  'rhyw',
  'rema',
  'red-velvet',
  'pinkpantheress',
  'perfume',
  'off',
  'nxworries-anderson-paak-knxwledge',
  'maral',
  'machine-girl',
  'loyle-carner',
  'le-tigre',
  'karenn',
  'joe-unknown',
  'joe-crepusculo',
  'jana-rush',
  'isabella-lovestory',
  'hudson-mohawke',
  'folamour',
  'drain-gang',
  'dj-playero',
  'darkside',
  'come',
  'central-cee',
  'brutalismus-3000',
  'boris',
  'amenra',
  'alex-g',
  'the-chills',
  'cloud-nothings',
  'four-tet',
  'two-ex',
  'diplo',
  'camelphat',
  'ben-bohmer',
  'windowspeak',
  'wunderhorse',
  'charlotte-adigery-and-bolis-pupul',
  'queralt-lahoz',
  'arab-strap',
  'lets-eat-grandma',
  'joey-badass',
  'kenny-beats',
  'optimo',
  'phoebe-bridgers',
  'santiago-motorizado',
  'the-smile',
  'not-a-cure-but-a-vehicle',
  'crystallmess',
  'wet-leg',
  'magdalena-bay',
  'pom-pom-squad',
  'meet-me-at-the-altar',
  'chill-mafia',
  'jhay-cortez',
  'grimes-dj-set',
  'nine-inch-nails',
  'omar-s',
  'arctic-monkeys',
  'buscabulla',
  'divino-nino',
  'mustafa',
  'arca',
  'mitski',
  'infectious-earworms',
  'maria-arnal-i-marcel-bages',
  'remi-wolf',
  'wind-atlas',
  'marie-davidson--and--loil-nu',
  'thurston-moore',
  'benny-sings',
  'kokoshca',
  'l-gante',
  'mmo',
  'la-zowi',
  'dead-can-dance',
  'tina',
  'warpaint',
  'ride',
  'phoenix',
  'jimi-somewhere',
  'pedro-mafama',
  'rita-vian',
  'kinetic',
  'navy-blue',
  'chet-faker',
  'czn',
  'victor',
  'mia',
  'sharon-van-etten-us',
  'slowdive',
  'beach-house',
  'mogwai',
  'malika',
  'monolink',
  'yung-prado',
  'yasmin-williams',
  'wesley-joseph',
  'vulk',
  'virginie',
  'viktor',
  'verushka-sirit',
  'torus',
  'the-soul-records-sound-system',
  'the-magnetic-fields',
  'str4ta',
  'steam-down',
  'spellling',
  'sourakata-koite',
  'sons-of-kemet',
  'soccer-96',
  'sinead-obrien',
  'sletar',
  'secret-night-gang',
  'sdh',
  'saoirse',
  'sandre',
  'sabla',
  'power-burkas',
  'philipp-sherbourme',
  'nala-sinephro',
  'nagui',
  'mt-formula',
  'mike',
  'mc-yallah-debmaster',
  'marie-montexier',
  'maria-sommerville',
  'maria-jaume',
  'lost-girls',
  'los-bitchos',
  'lord-apex',
  'lokey',
  'laani',
  'kmru',
  'king-hannah',
  'kero-kero-bonito',
  'keiyaa',
  'kamal-williams',
  'julio-tornero',
  'jokko',
  'jocstrap',
  'iii-considered',
  'ikram-boloum',
  'iceace',
  'hyd',
  'hidrogenesse',
  'hailu-mergia',
  'gazzi',
  'fenne-lilly',
  'femi',
  'ethan-p-flynn',
  'el-bloque-djs',
  'easyfun',
  'draing-gang-bladee-ecoo2k-thaiboy-digital',
  'doble-pletina',
  'dj-brava',
  'dj-black-low',
  'dead-normal',
  'dark0',
  'dance-system',
  'drop-marvin-bonnie-soul',
  'cucina-powera',
  'coagul',
  'chariot',
  'cassandra-jenkins',
  'ben-yart',
  'bandalos-chinos',
  'awesome-tapes',
  'austrohungaro',
  'aufwachen',
  'aksak-maboul',
  'afrikan-sciences',
  'shanti-celeste',
  'vladislav-delay',
  'verde-prato',
  'valentino-mora',
  'stein',
  'sonido-tupinamba',
  'sky-ferreira',
  'oli-xl',
  'namasenda',
  'mor-elian',
  'mary-anne-hobbs',
  'lcy-live',
  'kareem-ali',
  'guineu',
  'giveon',
  'gilles-peterson',
  'gaahls-wyrd',
  'enny',
  'da-souza',
  'craig-richards-b2b-nicolas-lutz',
  'connan-mockasin',
  'bikoko',
  'ashnikko',
  'arooj-aftab',
  'anz',
  'amaarae',
  'alex-cameron',
  'the-armed',
  'tarquim',
  'tainy',
  'stingray-313',
  'special-interest',
  'slikback',
  'senyawa',
  'sama-abdulhadi',
  'sad-night-dynamite',
  'romy',
  'rodrigo-cuevas',
  'rata-negra',
  'prospa',
  'moonchild-sanelly',
  'menta',
  'marta-knight',
  'maeve',
  'goldie',
  'genesis-owusu',
  'future-utopia',
  'duma',
  'dreamcatcher',
  'alanaire',
  'acemoma',
  'working-men-s-club',
  'wata-igarashi',
  'unai-muguruza',
  'spfdj',
  'sherelle-b2b-tim-reaper',
  'priya-ragu',
  'paranoid-1966',
  'pangaea',
  'oren-ambarchi-konrad-sprenger-phillip-sollmann',
  'nueva-vulcano',
  'nicki-nicole',
  'marco-shuttle',
  'mainline-magic-orchestra',
  'khea',
  'jensen-mcrae',
  'india-jordan',
  'efdemim',
  'dj-seinfield',
  'dj-pastis',
  'daniel-avery',
  'angele',
  'alizz',
  'yeah-yeah-yeahs',
  'thom-yorke-and-johnny-greenwood',
  'the-weather-nation',
  'tarta-relena',
  'solea-morente',
  'sofia-kourtessi',
  'run-the-jewels',
  'playboi-carti',
  'parquet-courts',
  'oranssi-pazuzu',
  'nick-cave-and-the-bad-seeds',
  'megan-thee-stallion',
  'lorde',
  'logic1000',
  'la-mafia-del-amor',
  'haai',
  'griff',
  'dua-lipa',
  'dorian-electra',
  'confeti-de-odio',
  'cmat',
  'cautious-clay',
  'cariño',
  'burna-boy',
  'anuel-aa',
  'viagra-boys',
  'pa-salieu',
  'molchat-doma',
  'jessie-ware',
  'jay-electronica',
  'jasmine-infiniti',
  'holly-humberstone',
  'high-on-fire',
  'gracey',
  'erika-de-casier',
  'cazzu',
  'angel-bat-dawid',
  'altin-gun',
  'ag-cook',
  'marc-peidro',
  'antonia-font',
  'los-planetas',
  'jp-saxe',
  'queer-on-stage-and-queer-in-action-pro2021',
  'sen-senra',
  'vodoo-club',
  'green-light-sound-system',
  'pierre-bourne',
  'metz',
  'ara-malikian',
  'rigoberta-bandini',
  'usn-world-cup-summer',
  'crim',
  'estrella-morente',
  'festival-cante-de-flamenco-de-la-mina',
  'ptazeta',
  'ladilla-rusa',
  'farruquito',
  'la-prohibida',
  'nicola-cruz',
  'kings-of-convenience',
  'novo-amor',
  'limperatrice',
  'jon-hopkins',
  'jose-gonzalez',
  'califato-3-4',
  'serrat-flamenc',
  'israel-fernandez',
  'diego-del-morao',
  'lucia-fernanda',
  'montse-cortes',
  'la-fabi',
  'duquende',
  'el-petit-de-cal-eril',
  'yellow-days',
  'carlos-sadness',
  'andres-suarez',
  'rociosaiz',
  'renaldo-and-clara',
  'therematic',
  'enric-montefusco',
  'deforme-semanal',
  'la-mulata',
  'sex-museum',
  'casero',
  'murais',
  'maria-escarmiento',
  'miqui-puig',
  'medalla',
  'aitor-etxebarria',
  'nidia',
  'terrence-dixon-live',
  'evian-christ',
  'marina-herlop',
  'the-murder-capital',
  'cecilo-g',
  'leite-sene',
  'los-punsetes',
  'la-mala',
  'triangulo-de-amor-bizarro',
  'mujeres',
  'mishima',
  'hinds',
  'foyone',
  'kiko-veneno',
  'belako',
  'bejo',
  'dorian',
  'monterrosa-la-prohibida',
  'soraya-sharonne',
  'el-yiyo',
  'ketama',
  'capullo-de-jerez',
  'diego-el-cigala',
  'zenet',
  'tribade',
  'smoking-souls',
  'los-mambo-jambo',
  'el-drogas',
  'sra-tomasa',
  'ciudad-jara',
  'think',
  'derivas',
  'play-the-game',
  'obscure',
  'the-amys-soul',
  'beabadoobee',
  'doja-cat',
  'kehlani',
  'porridge-radio',
  'jamie-xx',
  'gorillaz',
  'jorja-smith',
  'autechre',
  'el-mato-a-un-policia-motorizado',
  'anna-savy',
  'natalia',
  'los-hermanos-cubero',
  'chico-da-tina',
  'yung-beef-porto',
  'montanhas-azuis',
  'holy-nothing',
  'khruangbin',
  'mina-and-bryte',
  'mvria',
  'okay-kaya',
  'throes-the-shine',
  'david-bruno',
  'dj-firmeza',
  'dry-cleaning',
  'julia',
  'penelope-isles',
  'squid',
  'metronomy',
  'ferran-palau',
  'joan-soley',
  'paloma-mami',
  'yo-la-tengo',
  'bikini-kill',
  '100-gecs',
  'agoraphobia',
  'amelie-lens',
  'amaia',
  'anika-kunst',
  'aurora-halal',
  'automatic',
  'beach-bunny',
  'ben-ufo',
  'bicep-live',
  'bill-callahan',
  'bigklit',
  'zozo',
  'biscuit',
  'black-coffee',
  'blawan',
  'blond-ish',
  'black-lips',
  'brockhampton',
  'za',
  'buttechno',
  'big-freedia',
  'caribou',
  'c-tangana',
  'call-super-b2b-shanti-celeste',
  'carista',
  'd-tiffany',
  'john-t-gast',
  'carla',
  'young-thug',
  'beck',
  'desire',
  'chromatics',
  'young-marco',
  'chaos-in-the-cbd',
  'napalm-death',
  'nejo',
  'young-dolph',
  'chaqueta-de-chandal',
  'die-katapult',
  'joyhauser',
  'norsicaa',
  'coma',
  'william-djoko',
  'crudo-pimento',
  'ela-minus',
  'oklou',
  'diiv',
  'cuban-doll',
  'julietta-ferrari-dj',
  'dinosaur-jr',
  'kacey-musgraves',
  'disclosure',
  'bauhaus',
  'kamma-and-masalo',
  'dj-fra',
  'om',
  'fatima-yamaha',
  'boy-harsher',
  'kampire-and-decay-present-bunu-bop',
  'oscar-mulero',
  'brittany-howard',
  'otoboke-beaver',
  'kano',
  'pabllo-vittar',
  'dj-harvey',
  'faye-webster',
  'l-devine',
  'dj-kelvin',
  'pantocrator',
  'khaled',
  'kurt-vile',
  'mumdance',
  'yung-beef',
  'pressRelease',
  'dj-lizz',
  'la-favi',
  'vtss',
  'killavesi',
  'kim-gordon',
  'violet',
  'paul-heaton-and-jacqui-abbott',
  'kim-petras',
  'vagina-dentata-organ',
  'pelada',
  'uniforms',
  'pile',
  'king-gizzard-and-the-lizard-wizard',
  'pnl',
  'lana-del-rey',
  'rapsody',
  'king-krule',
  'richard-dawson',
  'king-princess',
  'tyler-the-creator',
  'rina-sawayama',
  'las-ligas-menores',
  'rolling-blackouts-coastal-fever',
  'rombo',
  'roosevelt',
  'lawrence-le-doux',
  'tropical-fuck-storm',
  'trapani',
  'sampa-the-great',
  'sangre-nueva-dj-python-kelman-duran-dj-florentino',
  'dj-shadow',
  'shame',
  'tops',
  'tim-burgess',
  'floating-points',
  'duki',
  'sherelle',
  'the-strokes',
  'shygirl',
  'the-national',
  'side-chick',
  'iggy-pop',
  'fontaines-dc',
  'noga-erez',
  'park-hye-jin',
  'freddie-gibbs-and-madlib',
  'the-jesus-and-mary-chain',
  'the-caretaker',
  'gabber-modus-operandi',
  'teto-preto',
  'gabriela-richardson',
  'teki-latex',
  'stellar-om-source',
  'girl-in-red',
  'squarepusher',
  'hannah-diamond',
  'special-request',
  'soto-asa',
  'health',
  'sonido-la-changa',
  'hector-oaks',
  'mike-dred',
  'mica-levi',
  'mecanica-popular',
  'mavis-staples',
  'massive-attack',
  'martha',
  'mariah-the-scientist',
  'maria-del-mar-bonet',
  'mano-le-tough',
  'manel',
  'mabel',
  'luna-ki',
  'los-hijos-de-yayo',
  'helado-negro',
  'dj-naranjito',
  'honey-dijon',
  'dj-whoisdualipa',
  'earl-sweatshirt',
  'iosonouncane',
  'einsturzende-neubauten',
  'isabella',
  'ivy-barkakati',
  'jakuzi',
  'jamila-woods',
  'jane-fitz',
  'jawbox',
  'lory-d',
  'jeff-mills',
  'jehnny-beth',
  'jenny-hval',
  'lorenzo-senni',
  'jessica-pratt',
  'joan-shelley',
  'le-hammond-inferno',
  'lingua-ignota',
  'koffee',
  'lila-tequila',
  'lightning-bolt',
  'les-savy-fav',
  'les-amazones-dafrique',
  'leon-vynehall',
  'le-nais',
  'afrodeutsche',
  'arrogance-arrogance',
  'arthur-verocai',
  'iron-maiden',
  'carolina-durante',
  'cigarettes-after-sex',
  'pavement',
  'little-simz',
  'lorena-alvarez',
];

export default artistsList;
